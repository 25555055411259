window.addEventListener("DOMContentLoaded", (event) => {
  installMenuHandlers();
  installAimingCanvasHandler();
});

function installMenuHandlers() {
  const menuBtn = document.getElementById("menubutton");
  menuBtn.onclick = (e) => {
    toggleMenu();
    e.stopPropagation();
  };

  document.body.onclick = (e) => {
    hideMenu();
    e.stopPropagation();
  };
}

function toggleMenu() {
  const menu = document.getElementById("menu");
  if (menu.classList.contains("hidden")) {
    menu.classList.remove("hidden");
  } else {
    menu.classList.add("hidden");
  }
}

function hideMenu() {
  const menu = document.getElementById("menu");
  menu.classList.add("hidden");
}

let aimingAngle = 45.0;
let previousAimingAngle = 0;
let mouseX = 0;
let mouseY = 0;
function installAimingCanvasHandler() {
  const canvas = document.getElementById("aiming-canvas");
  canvas.onmousemove = (e) => {
    const centerX = canvas.width / 2;
    const bottomY = canvas.height - 1;

    const bounds = canvas.getBoundingClientRect();
    mouseX = (e.offsetX / bounds.width) * canvas.width;
    mouseY = (e.offsetY / bounds.height) * canvas.height;

    const relativeX = mouseX - centerX;
    const relativeY = bottomY - mouseY;
    aimingAngle = Math.atan(relativeY / relativeX) * (180 / Math.PI);
    if (aimingAngle < 0) {
      aimingAngle = 180 + aimingAngle;
    }
    if (aimingAngle > 90 && mouseX > centerX) {
      aimingAngle = 0;
    } else if (aimingAngle < 90 && mouseX < centerX) {
      aimingAngle = 180;
    }
  };
  requestAnimationFrame(redrawAimingCanvas);
}

function redrawAimingCanvas() {
  if (aimingAngle != previousAimingAngle) {
    const canvas = document.getElementById("aiming-canvas");
    const ctx = canvas.getContext("2d", { alpha: false });
    ctx.fillStyle = "#412699";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const hypothenuse = canvas.height * 0.95;
    const deltaX = hypothenuse * Math.cos(aimingAngle * (Math.PI / 180));
    const deltaY = hypothenuse * Math.sin(aimingAngle * (Math.PI / 180));

    const centerX = canvas.width / 2;
    const bottomY = canvas.height - 1;

    const pointerX = centerX + deltaX;
    const pointerY = bottomY - deltaY;

    ctx.strokeStyle = "#fff";
    ctx.beginPath();
    ctx.moveTo(0, bottomY);
    ctx.lineTo(canvas.width, bottomY);
    ctx.moveTo(centerX, 1);
    ctx.lineTo(centerX, bottomY);
    ctx.stroke();

    ctx.strokeStyle = "#f542b9";
    ctx.beginPath();
    ctx.moveTo(centerX, bottomY);
    ctx.lineTo(pointerX, pointerY);
    ctx.stroke();

    ctx.fillStyle = "#f542b9";
    ctx.beginPath();
    ctx.arc(pointerX, pointerY, 6, 0, 2 * Math.PI);
    ctx.fill();

    if (aimingAngle > 90) {
      document.getElementById("aiming-canvas-absolute-command").innerText = `!aim @${((180.01 - aimingAngle) * -1).toFixed(2)}`;
    } else {
      document.getElementById("aiming-canvas-absolute-command").innerText = `!aim @+${aimingAngle.toFixed(2)}`;
    }
    document.getElementById("aiming-canvas-command").innerText = `!aim ${aimingAngle.toFixed(2)}`;
  }
  previousAimingAngle = aimingAngle;
  requestAnimationFrame(redrawAimingCanvas);
}
